import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../sdk";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useWarning } from "../../Provider/context";

const useRoomConfig = () => {
  const { hotelId } = useParams();
  const { authFetch } = useAuth();
  const { formEdit, setformEdit, handleRedirect } = useWarning();
  const [includedChange, setIncludedChange] = useState(true);
  const [excludedChange, setExcludedChange] = useState(true);
  const [mappingData, setMappingData] = useState([]);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const [isLoading, setIsLoading] = useState({
    getApi: false,
    saveApi: false,
  });
  const [snackBar, setSnackBar] = useState({
    open: false,
    msg: "",
    color: "",
  });

  const getAllMappingData = useCallback(async () => {
    try {
      setIsLoading({ saveApi: false, getApi: true });
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/rooms`,
      });
      const { data } = await get();
      if (data) {
        setMappingData(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading({ saveApi: false, getApi: false });
    }
  }, [authFetch, hotelId]);

  useEffect(() => {
    getAllMappingData();
  }, [getAllMappingData]);

  const handleRoomChange = useCallback(
    (event, rateMetricHotelCode) => {
      setIncludedChange(false);
      setformEdit(true);
      const val = event?.target?.value;

      setMappingData((prev) => {
        const index = prev.findIndex(
          (item) => item.rateMetricHotelCode === rateMetricHotelCode
        );

        return prev.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              roomType: item.roomType.map((room) => {
                if (val?.includes(room.roomDetailsId)) {
                  return { ...room, mapped: true };
                } else {
                  return { ...room, mapped: false };
                }
              }),
            };
          } else {
            return item;
          }
        });
      });
    },
    [setformEdit]
  );

  const handleExcludeRoomChange = useCallback(
    (event, index) => {
      setExcludedChange(false);
      setformEdit(true);
      const val = event?.target?.value;
      setMappingData((prev) => {
        return prev.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              roomType: item.roomType.map((room) => {
                if (val?.includes(room.roomDetailsId)) {
                  return { ...room, isExcluded: true, mapped: false };
                } else {
                  return { ...room, isExcluded: false };
                }
              }),
            };
          } else {
            return item;
          }
        });
      });
    },
    [setformEdit]
  );

  const handleClearMapping = useCallback(
    (rateMetricHotelCode) => {
      const index = mappingData.findIndex(
        (item) => item.rateMetricHotelCode === rateMetricHotelCode
      );
      setIncludedChange(false);
      setformEdit(true);
      setMappingData((prev) => {
        return prev.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              roomType: item.roomType.map((room) => ({
                ...room,
                mapped: false,
              })),
            };
          } else {
            return item;
          }
        });
      });
    },
    [setformEdit]
  );

  const handleClearExcludedMapping = useCallback(
    (index) => {
      setExcludedChange(false);
      setformEdit(true);
      setMappingData((prev) => {
        return prev.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              roomType: item.roomType.map((room) => ({
                ...room,
                isExcluded: false,
              })),
            };
          } else {
            return item;
          }
        });
      });
    },
    [setformEdit]
  );
  const saveMappingData = useCallback(
    async ({ clear }) => {
      try {
        setIsLoading({ getApi: false, saveApi: true });
        setIncludedChange(false);
        setformEdit(false);
        const { post } = await authFetch({
          path: `/hotel/${hotelId}/hotel-room-mapping-for-rate-shop`,
        });
        const requestBody = clear
          ? []
          : mappingData?.flatMap((item) => {
              return item?.roomType?.map((room) => {
                return {
                  roomDetailsId: room?.roomDetailsId,
                  isMapped: room.mapped,
                };
              });
            });

        const { response, data } = await post(requestBody);

        if (response.status === 201) {
          getAllMappingData();
          setSnackBar({
            open: true,
            msg: "Saved Successfully",
            color: "#228B22",
          });
        } else {
          setSnackBar({
            open: true,
            msg: data?.messageToUser || "Something went wrong",
            color: "#CA3433",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading({ getApi: false, saveApi: false });
      }
    },
    [authFetch, getAllMappingData, hotelId, mappingData, setformEdit]
  );
  const saveExcludedData = useCallback(async () => {
    try {
      setIsLoading({ getApi: false, saveApi: true });
      setExcludedChange(false);
      setformEdit(false);
      const { put } = await authFetch({
        path: `/hotel/${hotelId}/exclude-rooms`,
      });
      const requestBody = mappingData?.flatMap((item) => {
        return item?.roomType?.map((room) => {
          return {
            roomDetailsId: room?.roomDetailsId,
            isExcluded: room.isExcluded,
          };
        });
      });

      const { response, data } = await put(requestBody);

      if (response.status === 201 || response.status === 200) {
        getAllMappingData();
        setSnackBar({
          open: true,
          msg: "Saved Successfully",
          color: "#228B22",
        });
      } else {
        setSnackBar({
          open: true,
          msg: data?.messageToUser || "Something went wrong",
          color: "#CA3433",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading({ getApi: false, saveApi: false });
    }
  }, [authFetch, getAllMappingData, hotelId, mappingData, setformEdit]);

  return {
    mappingData,
    saveMappingData,
    handleRoomChange,
    snackBar,
    setSnackBar,
    isLoading,
    formEdit,
    hotelId,
    handleRedirect,
    openWarningModal,
    setOpenWarningModal,
    handleClearMapping,
    saveExcludedData,
    excludedChange,
    includedChange,
    handleExcludeRoomChange,
    handleClearExcludedMapping,
  };
};

export default useRoomConfig;
