import React, { useEffect } from "react";
import { Route, useHistory, useLocation, useParams } from "react-router-dom";
import {
  AlgorithmHeader as Header,
  HeaderCard,
  Heading,
  Padding,
  TabNav,
} from "./Styles";
import RoomMapping from "./RoomMapping";
import RoomFilter from "./RoomFilter";

const RoomConfig = () => {
  const { hotelId } = useParams();
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (location.pathname === `/hotel/${hotelId}/setup/room-mapping`) {
      history.replace(location.pathname + "/mapping");
    }
  }, [location.pathname, history, hotelId]);
  return (
    <>
      <Header>
        <HeaderCard>
          <TabNav
            exact
            to={`/hotel/${hotelId}/setup/room-mapping/mapping`}
            activeStyle={{
              color: "rgb(48,111,188)",
              borderLeft: "4px solid rgb(48,111,188)",
            }}
          >
            <Padding>
              <Heading>Rateshop Room Mappings</Heading>
            </Padding>
          </TabNav>
        </HeaderCard>

        <HeaderCard>
          <TabNav
            exact
            to={`/hotel/${hotelId}/setup/room-mapping/filter`}
            activeStyle={{
              color: "rgb(48,111,188)",
              borderLeft: "4px solid rgb(48,111,188)",
            }}
          >
            <Padding>
              <Heading>Rateshop Filters</Heading>
            </Padding>
          </TabNav>
        </HeaderCard>
      </Header>
      <div>
        <Route exact path="/hotel/:hotelId/setup/room-mapping/mapping">
          <RoomMapping/>
        </Route>
        <Route exact path="/hotel/:hotelId/setup/room-mapping/filter">
          <RoomFilter/>
        </Route>
      </div>
    </>
  );
};

export default RoomConfig;
