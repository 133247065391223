import { Box, Checkbox, TableContainer, Popover, Zoom, Dialog, DialogContent, IconButton, MenuItem } from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  DeleteOutline,
  Edit,
  InfoOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import React, { memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/system";
import {
  BASE_URL,
  getDateDDMM,
  getDay,
  getISODate,
  getDateDDMMYYYY,
  getHrMin,
} from "../../sdk";
import {
  DATE,
  DAY,
  IncDec,
  NoInc,
  RecommendationTable as Table,
  Tbody,
  Td,
  TextOnlyTooltip,
  TextOnlyTooltipDatePopover,
  TextOnlyTooltipPopover,
  Th,
  Thead,
  TooltipH,
  Trow as Tr,
  CardWrapper,
  SelectCell,
} from "../Styles";
import CalcPopOver from "./CalcPopOver";
import DatePopOver from "./DatePopOver";
import PopoverContent from "./PopoverContent";
import Typography from "@mui/material/Typography";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Stack, TextField } from "@mui/material";
import Draggable from "react-draggable";
import { RemarkButton } from "../../Pace/components/Styles";
import { useEditDemandLevel } from "../hooks/useEditDemandLevelModal";

const TH = styled(Th)``;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "100%" }}
      {...other}
    >
      {value === index && (
        <Box style={{ height: "100%" }} p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const RecommendationTable = memo(
  ({
    token,
    currentHotel,
    competitors,
    selectedDate,
    allFilesForDay,
    fileId,
    setnetworkMsg,
    setOpen,
    recommendedData,
    setIsRecalculatePrice,
    refreshPriceRecommendations,
    idx,
    manualDemandLevel,
    priceRecommendationWithCalc,
    priceOverride,
    uploadImage,
    sendRemark,
    selectedImages,
    setSelectedImages,
    remarkData,
    remarksData,
    setRemarksData,
    setIsPushRateDialogOpen,
    setPushRateFromDate,
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [popOver, setPopOver] = useState(undefined);
    const [today, setToday] = useState(false);
    const [noData, setNoData] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [popOverDate, setPopOverDate] = useState("");
    const [disablePrevDate, setDisablePrevDate] = useState(false);
    const [futureRemark, setFutureRemark] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [openImageDialog, setOpenImageDialog] = useState(false);

    const {
      demandLevels,
      setEditDemandData,
      onSave
    } = useEditDemandLevel(
      ()=>{setIsRecalculatePrice(true)}
    );

    const id = !!anchorEl ? "simple-popover" : undefined;
    console.log({ recommendedData });
    let previousLength = 0;

    const handleInput = (event) => {
      const bullet = "\u2022";
      const newLength = event.target.value.length;
      const characterCode = event.target.value.substr(-1).charCodeAt(0);

      if (newLength > previousLength) {
        if (characterCode === 10) {
          event.target.value = `${event.target.value}${bullet} `;
        } else if (newLength === 1) {
          event.target.value = `${bullet} ${event.target.value}`;
        }
      }
      previousLength = newLength;
    };

    const convertImageToBlob = async (image) => {
      const response = await fetch(image.uri);
      const blob = await response.blob();
      return blob;
    };

    const handleImagePreview = async (e,idx) => {
      if (e.target.files) {
        const images = [];
        for (let i = 0; i < e.target.files.length; i++) {
          const file = e.target.files[i];
          if (file.type.startsWith("image")) {
            const image = URL.createObjectURL(file);
            const blob = await convertImageToBlob({ uri: image });
            images.push(blob);
          } else {
            alert("Only image files are allowed.");
            break;
          }
        }
        const imageUrls = await uploadImage(images, popOverDate);
      // setSelectedImages((prevState) => [...prevState, ...imageUrl]);
        setPopOver((prevPopOver) => ({
          ...prevPopOver,
          remark: prevPopOver.remark.map((rem, index) =>
            index === idx
              ? {
                ...rem,
                remarkUpload: [
                  ...(rem?.remarkUpload || []),
                  ...imageUrls.map((img) => ({
                    url: img.imageURL,
                    uploadId: img.id,
                  })),
                ],
                isUpdated: true,
              }
              : rem
          ),
        }));
      }
    };

    const handleDeleteImage = (index, deletedImage) => {
      setSelectedImages((prevSelectedImages) =>
        prevSelectedImages.filter((img, i) => i !== index)
      );
      setPopOver((prevPopOver) => ({
      ...prevPopOver,
        remark: prevPopOver.remark.map((rem) =>
          rem.remarkUpload?.some((upload) => upload.url === deletedImage?.url)
            ? {
                ...rem,
                remarkUpload: rem.remarkUpload.filter(
                  (val) => val?.url !== deletedImage?.url
                ),
                isUpdated : true
              }
            : rem
        ),
      }));
    };
    const handleDeleteRemark = (indexToDelete) => {
      setPopOver((prevPopOver) => ({
        ...prevPopOver,
        remark: prevPopOver.remark.filter((_, index) => index !== indexToDelete),
      }));
    };

    const handleAddRemark = () => { 
    setPopOver((prevPopOver) => ({
      ...prevPopOver,
      remark: [
        ...(prevPopOver?.remark || []),
        {
          createdBy: "",
          createdAt: "",
          remark: "",
          isEditing: true,
          remarkUpload: [],
          isUpdated :true
        },
      ],
    }));
  };

  
    const handleOpenImageDialog = (images, index) => {
      setSelectedImages(images);
      setCurrentImageIndex(index);
      setOpenImageDialog(true);
    };

    const handleNextImage = () => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === selectedImages.length - 1 ? 0 : prevIndex + 1
      );
    };

    const handlePrevImage = () => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? selectedImages.length - 1 : prevIndex - 1
      );
    };
    

    const handleCloseImageDialog = () => {
      setOpenImageDialog(false);
    };

    const handleClose = () => {
      setAnchorEl(null);
      // setSelectedImages([]);
    };

    const handleSaveChanges = () => {
      setIsEditing(false);
      sendRemark(popOverDate, popOver);
      setAnchorEl(null);
    };

    const handleEditBtn = () => {
      setIsEditing(true);
    };

    const handleDiscard = () => {
      setIsEditing(false);
      const data = remarkData.find((item) => {
        return (
          String(new Date(item.remark.date).toLocaleDateString()) ===
          String(new Date(popOverDate).toLocaleDateString())
        );
      });
      setPopOver(data);
      setSelectedImages([]);
    };

    const handleRowClick = (date, e) => {
      setPopOverDate(date);
      const data = remarksData.find((item) => {
        return (
          String(new Date(item?.date).toLocaleDateString()) ===
          String(new Date(date)?.toLocaleDateString())
        );
      });
      if (data) {
        console.log(data,remarksData)
        setPopOver(data);
        setNoData(false);
        setToday(
          new Date()?.toLocaleDateString() ===
          new Date(date)?.toLocaleDateString()
      );
      } else {
        setPopOver(undefined);
        setNoData(true);
      }
      setAnchorEl(e.currentTarget);
      setDisablePrevDate(
        String(new Date().toLocaleDateString()) ===
          String(new Date(date).toLocaleDateString())
          ? false
          : new Date() > new Date(date)
      );
      setFutureRemark(new Date() < new Date(date));
    };

    const [popOverIdx, setPopOverIdx] = useState(-1);

    const [oldanchorEl, setOldAnchorEl] = useState(new Array(300).fill(null));
    const [showData, setShowData] = useState(true);
    const handlePopoverClose = (idxx) => {
      setOldAnchorEl(new Array(300).fill(null));
    };

    const openPop = oldanchorEl.map((data) => Boolean(data));

    const handleCloseStatus = () => {
      setOpen(false);
    };

    const sold = "Sold Out";
    const { hotelId } = useParams();
    var max_comp = 0;
    const removeOverride = async (id, checked, index, date) => {
      if (checked) {
        await fetch(`${BASE_URL}/hotel/${hotelId}/price-override/${id}`, {
          method: "DELETE",
          body: JSON.stringify({
            date,
          }),
          headers: {
            token,
          },
        })
          .then(() => refreshPriceRecommendations())
          .catch((err) => {
            setnetworkMsg("can't Override price");
            setOpen(true);
            console.log(err);
          });
      } else {
        setPopOverIdx(index);
      }
    };
    const compareDates = useCallback((date) => {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Set time to 00:00:00:000 for comparison
      const rateDate = new Date(date);

      if (rateDate.getTime() >= currentDate.getTime()) {
        return true;
      } else {
        return false;
      }
    }, []);

    const handlePushRateClick = useCallback(
      (date) => {
        const datetoPush = new Date(date);
        setPushRateFromDate({
          year: datetoPush.getFullYear(),
          month: datetoPush.getMonth() + 1,
          day: datetoPush.getDate(),
        });
        setIsPushRateDialogOpen(true);
      },
      [setIsPushRateDialogOpen, setPushRateFromDate]
    );
    return (
      <>
        {allFilesForDay[0] && (
          <CardWrapper>
            {allFilesForDay.map((file, index) => (
              <TabPanel value={fileId} index={file.id} key={index}>
                <TableContainer className="container">
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    style={{ width: "100%" }}
                  >
                    <Thead>
                      <Tr>
                        <TH
                          style={{
                            backgroundColor: "#306fbc",
                            color: "#ffffff",
                            borderRadius: "8px 0px 0px 0px",
                            verticalAlign: "middle",
                            padding: "10px 0px",
                          }}
                        >
                          Date
                        </TH>
                        <TH
                          style={{
                            backgroundColor: "#306fbc",
                            color: "#ffffff",
                            verticalAlign: "middle",
                            padding: "10px 0px",
                          }}
                        >
                          DOW
                        </TH>
                        <TooltipH
                          disableFocusListener
                          disableTouchListener
                          title={"City Demand Level/ Hotel Demand Level"}
                          placement="top"
                          arrow
                        >
                          <TH
                            style={{
                              backgroundColor: "#306fbc",
                              color: "#ffffff",
                              verticalAlign: "middle",
                              padding: "10px 0px",
                            }}
                          >
                            CL/HL
                          </TH>
                        </TooltipH>
                        <TH
                          style={{
                            backgroundColor: "#306fbc",
                            color: "#ffffff",
                            verticalAlign: "middle",
                            padding: "10px 0px",
                          }}
                        >
                          Occ%
                        </TH>
                        <TH
                          style={{
                            backgroundColor: "#306fbc",
                            color: "#ffffff",
                            verticalAlign: "middle",
                            padding: "10px 0px",
                          }}
                        >
                          Rooms
                          <br />
                          Left
                        </TH>
                        <TooltipH
                          disableFocusListener
                          disableTouchListener
                          title={"Demand Level"}
                          placement="top"
                          arrow
                        >
                          <TH
                            style={{
                              backgroundColor: "#306fbc",
                              color: "#ffffff",
                              verticalAlign: "middle",
                              padding: "10px 0px",
                            }}
                          >
                            DL
                          </TH>
                        </TooltipH>

                        <TH
                          style={{
                            backgroundColor: "#306fbc",
                            color: "#ffffff",
                            verticalAlign: "middle",
                            padding: "10px 0px",
                          }}
                        >
                          Current
                          <br />
                          Price
                        </TH>

                        <TH
                          style={{
                            backgroundColor: "#306fbc",
                            color: "#ffffff",
                            verticalAlign: "middle",
                            padding: "10px 0px",
                          }}
                        >
                          Automated
                          <br />
                          Price
                        </TH>

                        <TH
                          style={{
                            backgroundColor: "#306fbc",
                            color: "#ffffff",
                            verticalAlign: "middle",
                            padding: "10px 8px",
                          }}
                        >
                          Price
                          <br />
                          Difference
                        </TH>
                        {showData && (
                          <TooltipH
                            disableFocusListener
                            disableTouchListener
                            title={"Override"}
                            placement="top"
                            arrow
                          >
                            <TH
                              style={{
                                backgroundColor: "#306fbc",
                                color: "#ffffff",
                                verticalAlign: "middle",
                                padding: "10px 5px",
                              }}
                            >
                              OVR
                            </TH>
                          </TooltipH>
                        )}

                        {showData && (
                          <TH
                            style={{
                              backgroundColor: "#306fbc",
                              color: "#ffffff",
                              verticalAlign: "middle",
                              padding: "10px 0px",
                            }}
                          >
                            Override
                            <br />
                            Price
                          </TH>
                        )}
                        {showData && (
                          <TH
                            style={{
                              backgroundColor: "#306fbc",
                              color: "#ffffff",
                              verticalAlign: "middle",
                              padding: "10px 7px",
                            }}
                          >
                            Remarks for Override
                          </TH>
                        )}
                        {/* 
                        {data.competitorDetails
                              ? data.competitorDetails
                                  .sort(
                                    (a, b) => a.competitorID - b.competitorID
                                  )} */}
                        {recommendedData.pricingDetails &&
                          recommendedData.pricingDetails[idx] &&
                          recommendedData?.pricingDetails[
                            idx
                          ]?.competitorDetails
                            ?.sort((a, b) => a.competitorID - b.competitorID)
                            .map((datax, idx) => {
                              return (
                                <>
                                  {idx === 0 ? (
                                    <Th
                                      style={{
                                        backgroundColor: "#306fbc",
                                        color: "#ffffff",
                                        border: "1px solid rgba(0, 0, 0, 0.12)",
                                        boxSizing: "border-box",
                                        boxShadow:
                                          "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
                                        verticalAlign: "middle",
                                        padding: "10px 2px",
                                      }}
                                      key={idx}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            position: "absolute",
                                            marginLeft: "-12px",

                                            width: "16px",
                                            height: "16px",

                                            background: "#FFFFFF",

                                            borderRadius: "50%",
                                            color: "#306fbc",
                                          }}
                                          onClick={() => setShowData(!showData)}
                                        >
                                          {showData ? (
                                            <KeyboardArrowLeft
                                              style={{
                                                height: "16px ",
                                                marginLeft: "-4px",
                                              }}
                                            />
                                          ) : (
                                            <KeyboardArrowRight
                                              style={{
                                                height: "16px ",
                                                marginLeft: "-4px",
                                              }}
                                            />
                                          )}
                                        </div>
                                        <div
                                          style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {datax.competitorName}
                                        </div>
                                      </div>
                                    </Th>
                                  ) : (
                                    <Th
                                      style={{
                                        backgroundColor: "#306fbc",
                                        color: "#ffffff",
                                        border: "1px solid rgba(0, 0, 0, 0.12)",
                                        boxSizing: "border-box",
                                        boxShadow:
                                          "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
                                        verticalAlign: "middle",
                                        padding: "10px 5px",
                                      }}
                                      key={idx}
                                    >
                                      {datax.competitorName}
                                    </Th>
                                  )}
                                </>
                              );
                            })}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {recommendedData &&
                        recommendedData.pricingDetails &&
                        recommendedData.pricingDetails[0] &&
                        recommendedData.pricingDetails.map((data, index) => (
                          <Tr key={index} hover classes={{ hover: "rowHover" }}>
                            {data?.events != null ? (
                              <TextOnlyTooltipDatePopover
                                TransitionComponent={Zoom}
                                TransitionProps={{ timeout: 400 }}
                                disableFocusListener
                                disableTouchListener
                                placement="right-start"
                                arrow
                                interactive
                                title={
                                  <DatePopOver
                                    index={index}
                                    date={new Date(
                                      data.date
                                    ).toLocaleDateString("en-GB")}
                                    day={Days[new Date(data.date).getDay()]}
                                    eventArray={data?.events}
                                  />
                                }
                              >
                                <Td
                                  style={{
                                    minWidth: "50px",
                                    background:
                                      "linear-gradient(rgb(128,128,128,0.8), rgb(255, 255, 255,1))",
                                    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                                  }}
                                >
                                  {(new Date()?.toLocaleDateString() === new Date(data.date)?.toLocaleDateString()) ||
                                    remarksData?.some(({ date }) => String(new Date(date).toLocaleDateString()) === String(new Date(data.date)?.toLocaleDateString())) ? 
                                    <DATE>
                                    <div
                                      onClick={(e) => {
                                        handleRowClick(data.date, e);
                                      }}
                                      style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                                    >
                                        {remarksData?.some(({ date }) => String(new Date(date).toLocaleDateString()) === String(new Date(data.date)?.toLocaleDateString())) ?
                                          <InfoOutlined fontSize="8px" style={{ marginLeft: "-14px" }} />
                                          : null}
                                        <Typography fontSize={"14px"}>{getDateDDMM(data.date)}{" "}</Typography>
                                    </div>
                                  </DATE> : <DATE>
                                      {getDateDDMM(data.date)}{" "}
                                  </DATE>}
                                  {/* <DATE>
                                    <div>{getDateDDMM(data.date)} </div>
                                    <DAY>{getDay(data.date)}</DAY>
                                  </DATE> */}
                                </Td>
                              </TextOnlyTooltipDatePopover>
                            ) : (
                              <Td
                                style={{
                                  minWidth: "50px",
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontsize: "14px",
                                  lineHeight: "20px",
                                  borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                              >
                                <DATE>
                                  {currentHotel?.isChannelManagerEnabled && (
                                    <Button
                                      disabled={!compareDates(data.date)}
                                      sx={{ textTransform: "capitalize" }}
                                      onClick={() =>
                                        handlePushRateClick(data?.date)
                                      }
                                    >
                                      Push rate
                                    </Button>
                                  )}
                                  <div
                                    onClick={(e) => {
                                      handleRowClick(data.date, e);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {getDateDDMM(data.date)}{" "}
                                  </div>
                                </DATE>
                              </Td>
                            )}
                            {data?.events != null ? (
                              <TextOnlyTooltipDatePopover
                                TransitionComponent={Zoom}
                                TransitionProps={{ timeout: 400 }}
                                disableFocusListener
                                disableTouchListener
                                placement="right-start"
                                arrow
                                interactive
                                title={
                                  <DatePopOver
                                    index={index}
                                    date={new Date(
                                      data.date
                                    ).toLocaleDateString("en-GB")}
                                    day={Days[new Date(data.date).getDay()]}
                                    eventArray={data?.events}
                                  />
                                }
                              >
                                <Td
                                  style={{
                                    minWidth: "30px",
                                    background:
                                      "linear-gradient(rgb(128,128,128,0.8), rgb(255, 255, 255,1))",
                                  }}
                                >
                                  <DATE>
                                    <DAY>{getDay(data.date)}</DAY>
                                  </DATE>
                                </Td>
                              </TextOnlyTooltipDatePopover>
                            ) : (
                              <Td
                                style={{
                                  minWidth: "30px",
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontsize: "14px",
                                  lineHeight: "20px",
                                }}
                              >
                                <DATE>
                                  <DAY>{getDay(data.date)}</DAY>
                                </DATE>
                              </Td>
                            )}
                            
                            {data.calculationSettings && (data.calculationSettings
                              .demandLevelDLF || data.calculationSettings
                              .cityDemandLevelDLF) ?
                            data.calculationSettings
                              .demandLevelDLF ? (
                              <Td
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  color: "#FFFFFF",
                                  background: `${
                                    data.calculationSettings.demandLevelDLF.color
                                  }`,
                                }}
                              >
                                {
                                  data.calculationSettings.demandLevelDLF.symbol
                                }
                              </Td>
                              ) : (
                                <Td
                                  style={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    color: "#FFFFFF",
                                    background: `${
                                      data.calculationSettings.cityDemandLevelDLF.color
                                    }`,
                                  }}
                                  >
                                    <SelectCell
                                      variant="standard"
                                      sx={{ '&::before': { border: 'none' }, '&::after': { border: 'none' }, '&:hover:not(.Mui-disabled):before': { border: 'none' } }}
                                      size="small"
                                      value={data.calculationSettings.cityDemandLevelDLF.symbol}
                                      onChange={(event) => {
                                        if (event.target.value !== data.calculationSettings.cityDemandLevelDLF.symbol) {
                                          const newArray =[{
                                            startDate: new Date(
                                                data.date
                                            ),
                                            endDate: new Date(
                                                data.date
                                            ),
                                            days: [new Date(data.date).getDay()],
                                            hotelDemandLevelSymbol: data?.calculationSettings?.demandLevelDLF?.symbol??'',
                                            cityDemandLevelSymbol: event.target.value,
                                            overrideSymbol: false,
                                            cityOverrideSymbol: true
                                        }];
                                        setEditDemandData(data);
                                        onSave(newArray);
                                        }
                                      }}
                                      >
                                      {demandLevels.map((demandLevelsOption) => (
                                        <MenuItem
                                          key={demandLevelsOption.value + index}
                                          style={{
                                            cursor: "pointer",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            color: "#FFFFFF",
                                            background: `${
                                              demandLevelsOption.color
                                            }`,
                                          }}
                                          value={demandLevelsOption.symbol}>
                                          {demandLevelsOption.symbol}
                                        </MenuItem>
                                        ))}
                                      </SelectCell>
                                  {/* {
                                  } */}
                                </Td>
                              ) : (
                              <Td>-</Td>
                            )}
                            <Td
                              style={
                                data.color
                                  ? {
                                      background: `linear-gradient(${data.color}, #FFFFFF)`,
                                      borderRight: "solid 1px #FFFFFF",
                                      textAlign: "center",
                                      fontFamily: "Roboto",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      fontsize: "14px",
                                      lineHeight: "20px",
                                    }
                                  : {}
                              }
                            >
                              {Math.round(data.occupancyPercentage)}%
                            </Td>
                            <Td
                              style={{
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontsize: "14px",
                                lineHeight: "20px",
                              }}
                            >
                              {data.roomLeft}
                            </Td>
                            {data.calculationSettings &&
                            data.calculationSettings
                              .actualDemandLvlMultiplier ? (
                              <Td
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  color: "#FFFFFF",
                                  background: `${
                                    manualDemandLevel.find(
                                      (dl) =>
                                        dl.symbol ===
                                        data.calculationSettings
                                          .actualDemandLvlMultiplier.symbol
                                    ).color
                                  }`,
                                }}
                              >
                                {
                                  data.calculationSettings
                                    .actualDemandLvlMultiplier.symbol
                                }
                              </Td>
                            ) : (
                              <Td>-</Td>
                            )}
                            <Td
                              style={{
                                whiteSpace: "nowrap",
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontsize: "14px",
                                lineHeight: "20px",
                              }}
                            >
                              {data?.isSoldOut ? (
                                <p>Sold Out</p>
                              ) : data?.currentPrice === -2 ? (
                                <p>-</p>
                              ) : (
                                data?.currentPrice
                              )}
                            </Td>

                            {priceRecommendationWithCalc ? (
                              <TextOnlyTooltipPopover
                                disableFocusListener
                                disableTouchListener
                                placement="right-start"
                                arrow
                                interactive
                                title={
                                  <CalcPopOver
                                    calc={data.calculationSettings}
                                    handlePopoverClose={handlePopoverClose}
                                    index={index}
                                    baseMinPrice={data?.hotelPrice}
                                    reccPrice={
                                      !Number.isInteger(data.calculatePrice)
                                        ? Math.round(
                                            data.calculatePrice + Number.EPSILON
                                          )
                                        : data.calculatePrice
                                    }
                                    date={new Date(
                                      data.date
                                    ).toLocaleDateString("en-GB")}
                                    day={Days[new Date(data.date).getDay()]}
                                    checkOverridePrice={
                                      data?.isOverrideCalculatedPrice
                                    }
                                    maxThresholdPrice={data?.maxThresholdPrice}
                                  />
                                }
                                PopperProps={{
                                  popperOptions: {
                                    positionFixed: true,
                                    modifiers: {
                                      preventOverflow: {
                                        enabled: true,
                                        boundariesElement: "window",
                                      },
                                    },
                                  },
                                  sx: {
                                    ".MuiTooltip-tooltip": {
                                      backgroundColor: "white",
                                      border: "1px solid grey",
                                      boxShadow: "0px 4px 8px #00000035",
                                    },
                                  },
                                }}
                              >
                                {data?.hotelPrice &&
                                data.calculatePrice > data?.hotelPrice ? (
                                  <Td
                                    style={{
                                      maxWidth: "40px",
                                      padding: "0px",
                                      fontFamily: "Roboto",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      fontsize: "14px",
                                      lineHeight: "20px",
                                    }}
                                  >
                                    {data?.isOverrideCalculatedPrice &&
                                    data?.maxThresholdPrice
                                      ? !Number.isInteger(
                                          data.maxThresholdPrice
                                        )
                                        ? Math.round(
                                            data.maxThresholdPrice +
                                              Number.EPSILON
                                          )
                                        : data.maxThresholdPrice
                                      : !Number.isInteger(data.calculatePrice)
                                      ? Math.round(
                                          data.calculatePrice + Number.EPSILON
                                        )
                                      : data.calculatePrice}
                                  </Td>
                                ) : (
                                  <Td
                                    style={{
                                      maxWidth: "40px",
                                      padding: "0px",
                                      fontFamily: "Roboto",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      fontsize: "14px",
                                      lineHeight: "20px",
                                    }}
                                  >
                                    {!Number.isInteger(data?.hotelPrice)
                                      ? Math.round(
                                          data?.hotelPrice + Number.EPSILON
                                        )
                                      : data?.hotelPrice}
                                    <span
                                      style={{
                                        color: "red",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      *
                                    </span>
                                  </Td>
                                )}
                              </TextOnlyTooltipPopover>
                            ) : data?.hotelPrice &&
                              data.calculatePrice > data?.hotelPrice ? (
                              <Td
                                style={{
                                  width: "10px",
                                  padding: "0px",
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontsize: "14px",
                                  lineHeight: "20px",
                                }}
                              >
                                {data?.isOverrideCalculatedPrice &&
                                data?.maxThresholdPrice
                                  ? !Number.isInteger(data.maxThresholdPrice)
                                    ? Math.round(
                                        data.maxThresholdPrice + Number.EPSILON
                                      )
                                    : data.maxThresholdPrice
                                  : !Number.isInteger(data.calculatePrice)
                                  ? Math.round(
                                      data.calculatePrice + Number.EPSILON
                                    )
                                  : data.calculatePrice}
                              </Td>
                            ) : (
                              <Td
                                style={{
                                  width: "10px",
                                  padding: "0px",
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontsize: "14px",
                                  lineHeight: "20px",
                                }}
                              >
                                {!Number.isInteger(data?.hotelPrice)
                                  ? Math.round(
                                      data?.hotelPrice + Number.EPSILON
                                    )
                                  : data?.hotelPrice}
                                *
                              </Td>
                            )}
                            <Td
                              style={{
                                textAlign: "left",
                                paddingRight: "16px",
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontsize: "14px",
                                lineHeight: "20px",
                              }}
                            >
                              {data.incDec === 0 ? (
                                <NoInc style={{ justifyContent: "center" }}>
                                  -
                                </NoInc>
                              ) : (
                                <IncDec style={{ justifyContent: "center" }}>
                                  {
                                    <div
                                      style={{
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                      }}
                                    >
                                      {parseInt(data.incDec)}
                                    </div>
                                  }
                                  {data.incDec > 0 ? (
                                    <img
                                      style={{ paddingLeft: "8px" }}
                                      src="/assets/inc-icon.svg"
                                      alt="Increase Icon"
                                    />
                                  ) : (
                                    <img
                                      style={{ paddingLeft: "8px" }}
                                      src="/assets/dec-icon.svg"
                                      alt="Decrease Icon"
                                    />
                                  )}
                                </IncDec>
                              )}
                            </Td>
                            {showData && (
                              <div>
                                {priceOverride &&
                                getISODate(selectedDate).split("T")[0] ===
                                  getISODate(new Date()).split("T")[0] &&
                                data.date.split("T")[0] >=
                                  getISODate(new Date()).split("T")[0] &&
                                file.status === "calculated" ? (
                                  <PopupState
                                    variant="popover"
                                    popupId={`${data.id}`}
                                  >
                                    {(popupState) => (
                                      <>
                                        <Td>
                                          <Checkbox
                                            checkedIcon={<RadioButtonChecked />}
                                            icon={<RadioButtonUnchecked />}
                                            {...bindTrigger(popupState)}
                                            checked={data.isOverridePrice}
                                            onChange={(e) => {
                                              removeOverride(
                                                fileId,
                                                data.isOverridePrice,
                                                index,
                                                data.date
                                              );
                                            }}
                                            sx={{ color: "black" }}
                                          />
                                        </Td>
                                        <Popover
                                          {...bindPopover(popupState)}
                                          open={popOverIdx === index}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center",
                                          }}
                                        >
                                          <PopoverContent
                                            setPopOverIdx={setPopOverIdx}
                                            date={data.date}
                                            refreshPriceRecommendations={
                                              refreshPriceRecommendations
                                            }
                                            recommendedPrice={
                                              data.calculatePrice
                                            }
                                            setnetworkMsg={setnetworkMsg}
                                            setOpen={setOpen}
                                            fileId={fileId}
                                          />
                                        </Popover>
                                      </>
                                    )}
                                  </PopupState>
                                ) : (
                                  <Td>
                                    <Checkbox
                                      disabled
                                      color="default"
                                      checkedIcon={<RadioButtonChecked />}
                                      icon={<RadioButtonUnchecked />}
                                      checked={data.isOverridePrice}
                                    />
                                  </Td>
                                )}
                              </div>
                            )}
                            {showData && (
                              <Td>
                                {!Number.isInteger(data.overridePrice)
                                  ? Math.round(
                                      (data.overridePrice + Number.EPSILON) *
                                        100
                                    ) / 100
                                  : data.overridePrice !== 0
                                  ? data.overridePrice
                                  : "-"}
                              </Td>
                            )}
                            {showData && (
                              <TextOnlyTooltip
                                disableFocusListener
                                disableTouchListener
                                title={data.overrideReason}
                                arrow
                              >
                                <Td
                                  style={{ minWidth: "100px", width: "200px" }}
                                >
                                  <div
                                    style={{
                                      height: "30px",
                                      maxWidth: "200px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      lineHeight: "30px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {data.overrideReason || "-"}
                                  </div>
                                </Td>
                              </TextOnlyTooltip>
                            )}

                            {data.competitorDetails
                              ? data.competitorDetails
                                  .sort(
                                    (a, b) => a.competitorID - b.competitorID
                                  )
                                  .map((datax, idx) =>
                                    datax.price === -2 ? (
                                      <Td
                                        key={idx}
                                        style={{
                                          background:
                                            "linear-gradient(#757575,#FFFFFF)",
                                          fontWeight: "bold",
                                          borderRight: "solid 1px #FFFFFF",
                                          whiteSpace: "nowrap",
                                          width: "150px",
                                          padding: "0px 0px",
                                          borderRadius: "4px",
                                          font:
                                            "normal normal 400 14px/20px Roboto",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          N/A
                                        </div>
                                      </Td>
                                    ) : (
                                      <Td
                                        key={idx}
                                        style={
                                          data.currentPrice < datax.price ||
                                          datax.price === -1
                                            ? {
                                                background:
                                                  "linear-gradient(#4ABD3C,#FFFFFF)",
                                                fontWeight: "bold",
                                                borderRight:
                                                  "solid 1px #FFFFFF",
                                                whiteSpace: "nowrap",
                                                width: "150px",
                                                padding: "0px 0px",
                                                borderRadius: "4px",
                                                font:
                                                  "normal normal 400 14px/20px Roboto",
                                              }
                                            : {
                                                background:
                                                  "linear-gradient(#D30E08,#FFFFFF)",
                                                fontWeight: "bold",
                                                borderRight:
                                                  "solid 1px #FFFFFF",
                                                whiteSpace: "nowrap",
                                                width: "150px",
                                                padding: "0px 0px",
                                                borderRadius: "4px",
                                                font:
                                                  "normal normal 400 14px/20px Roboto",
                                              }
                                        }
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <div
                                            style={{
                                              margin: "auto",
                                              width: "85px",
                                              background:
                                                datax.price === -1
                                                  ? "linear-gradient(#757575,#FFFFFF)"
                                                  : "",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              height: "42px",
                                              color:
                                                datax.price === -1
                                                  ? "#000000"
                                                  : "",
                                            }}
                                          >
                                            {datax.isSoldOut
                                              ? "Sold Out"
                                              : datax.price}
                                          </div>
                                          <div
                                            style={
                                              datax.priceDifference === -1 ||
                                              datax.priceDifference >= 0
                                                ? {
                                                    background:
                                                      datax.priceDifference ===
                                                      -1
                                                        ? "linear-gradient(rgb(167, 166, 168),#FFFFFF)"
                                                        : data.currentPrice <
                                                            datax.price ||
                                                          datax.price === -1
                                                        ? "linear-gradient(rgb(75,193,60,0.1), rgb(255, 255, 255,1))"
                                                        : "linear-gradient(rgb(75,193,60,0.9), rgb(255, 255, 255,1))",
                                                    height: "42px",
                                                    padding: "18px 0px",
                                                    width: "64px",
                                                    font:
                                                      "normal normal 400 10px/12px Roboto",
                                                    color:
                                                      datax.priceDifference ===
                                                      -1
                                                        ? "#000000"
                                                        : "",
                                                  }
                                                : {
                                                    background: !(
                                                      data.currentPrice <
                                                        datax.price ||
                                                      datax.price === -1
                                                    )
                                                      ? "linear-gradient(rgb(211,14,8,0.1), rgb(255, 255, 255,1))"
                                                      : "linear-gradient(rgb(211,14,8,0.9), rgb(255, 255, 255,1))",
                                                    height: "42px",
                                                    padding: "18px 0px",
                                                    width: "64px",
                                                    font:
                                                      "normal normal 400 10px/12px Roboto",
                                                  }
                                            }
                                          >
                                            <div
                                              style={{
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                              }}
                                            >
                                              {datax.priceDifference === -1
                                                ? "sold"
                                                : datax.priceDifference}
                                            </div>
                                          </div>
                                        </div>
                                      </Td>
                                    )
                                  )
                              : Array(max_comp)
                                  .fill(0)
                                  .map((_, idx) => (
                                    <Td
                                      key={idx}
                                      style={{ justifyContent: "center" }}
                                    >
                                      -
                                    </Td>
                                  ))}
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </TabPanel>
            ))}
          </CardWrapper>
        )}
        <Draggable>
          {noData || today ? (
            disablePrevDate ? (
              <>
                <div style={{ cursor: "default" }}></div>
              </>
              // <Popover
              //   id={id}
              //   open={!!anchorEl}
              //   anchorEl={anchorEl}
              //   onClose={handleClose}
              //   anchorOrigin={{
              //     vertical: "bottom",
              //     horizontal: "center",
              //   }}
              //   sx={{
              //     "& .MuiPopover-paper": {
              //       border: "1px solid #000000",
              //       maxWidth: "587px",
              //       maxHeight: "435px",
              //     },
              //   }}
              // >
              //   <Stack direction={"row"} p={2} gap={4}>
              //     <Typography>No Remark</Typography>
              //     <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
              //   </Stack>
              // </Popover>
            ) : futureRemark ? (
              <>
                <div style={{ cursor: "default" }}></div>
              </>
            ) : (
              <Popover
                id={id}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                sx={{
                  "& .MuiPopover-paper": {
                    border: "1px solid #000000",
                    maxWidth: "587px",
                    maxHeight: "550px",
                    padding: "0 8px 16px",
                    minWidth: "500px",
                  },
                }}
              >
                <>
                  <Stack
                    direction="row"
                    sx={{
                      position: "sticky",
                      top: 0,
                      padding: "16px 8px 8px",
                      background: "white",
                      zIndex: 5,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        flexGrow: 1,
                        textAlign: "center",
                        cursor: "move",
                      }}
                    >
                      Remark of{" "}
                      {getDateDDMMYYYY(
                        popOver?.date ? popOver?.date : popOverDate
                      )}{" "}
                    </Typography>
                    <CloseIcon
                      sx={{ cursor: "pointer", mr: "10px" }}
                      onClick={handleClose}
                    />
                  </Stack>

                  <Stack gap={1} mb={2} px={2}>
                    {popOver?.remark?.map((item, idx) => (
                      <Stack
                        key={item.id??(idx+"newRemark")}
                        sx={{
                          border: "1px solid #7474744D",
                          borderRadius: "8px",
                          position: "relative",
                          padding: "8px",
                        }}
                        gap={"10px"}
                        pl={2}
                        pr={"20px"}
                      >
                        {!item?.id && (
                          <DeleteOutline
                            sx={{
                              cursor: "pointer",
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              color: "#f1592b",
                              zIndex:1
                            }}
                            onClick={() => handleDeleteRemark(idx)}
                          />
                        )}
                        {item?.createdAt && (
                          <Stack direction="row" justifyContent={"space-between"}>
                            <Typography
                              sx={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              {item?.createdBy ?? ""}{" "}
                            </Typography>
                            <Typography
                              sx={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              {getDateDDMMYYYY(popOver?.date)}{" "}
                              {getHrMin(item?.createdAt ?? "")}{" "}
                            </Typography>
                          </Stack>
                        )}
                        <Box style={{
                          padding: "0",
                          display: "flex",
                          alignItems: "center"
                        }}>
                          {!item.isEditing ?
                            <>
                              <Typography sx={{
                                flex: 1
                              }}>
                                {item?.remark}
                              </Typography>
                              <IconButton sx={{ padding: "0" }}
                                onClick={(e) => {
                                  const updatedRemarkText = e.target.value;
                                  setPopOver((prevPopOver) => ({
                                    ...prevPopOver,
                                    remark: prevPopOver.remark.map((rem, index) =>
                                      index === idx
                                        ? { ...rem, isEditing: true }
                                        : rem
                                    ),
                                  }));
                                }}>
                                <Edit />
                              </IconButton>
                            </>
                            :
                            <TextField
                            sx={{
                              flex: 1,
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                            }}
                            slotProps={{
                              input: {
                                readOnly: true,
                              },
                            }}
                            multiline
                            fullWidth
                            value={item?.remark}
                            onChange={(e) => {
                              const updatedRemarkText = e.target.value;
                              setPopOver((prevPopOver) => ({
                                ...prevPopOver,
                                remark: prevPopOver.remark.map((rem, index) =>
                                  index === idx
                                    ? { ...rem, remark: updatedRemarkText, isUpdated : true }
                                    : rem
                                ),
                              }));
                            }}
                            variant="standard"
                            placeholder="Type your remark..."
                            InputProps={{ disableUnderline: "true" }}
                            // onInput={handleInput}
                          />}
                        </Box>

                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "row",
                            gap: "8px",
                          }}
                        >
                          {[
                            ...(!!item?.remarkUpload?.length
                              ? item?.remarkUpload
                              : []),
                          ].map((img, index) => {
                            return (
                              <Box
                                // mt={3}
                                key={index}
                                style={{
                                  position: "relative",
                                  padding: "0px",
                                }}
                              >
                                {item.isEditing ? <CloseIcon
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: "-4px",
                                    right: "-8px",
                                    color: "white",
                                    backgroundColor: "#6C7270",
                                    borderRadius: "50%",
                                    height: "17px",
                                    width: "18px",
                                  }}
                                  onClick={() => handleDeleteImage(index, img)}
                                /> : null}

                                <img
                                  src={img?.url}
                                  alt="Preview"
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    objectFit: "cover",
                                    margin: 1,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleOpenImageDialog(
                                      item?.remarkUpload,
                                      index
                                    )
                                  }
                                />
                              </Box>
                            );
                          })}

                          {item.isEditing ? <label>
                            <Stack
                              direction="row"
                              ml={2}
                              // mt={"4px"}
                              height={"100%"}
                              alignItems={"center"}
                            >
                              <AddPhotoAlternateIcon
                                onClick={(e) => handleImagePreview(e, idx)}
                                sx={{ color: "grey", cursor: "pointer" }}
                              />
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  color: "grey",
                                  ml: 1,
                                  cursor: "pointer",
                                }}
                              >
                                Add Image
                              </Typography>
                              <TextField
                                type="file"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                sx={{ visibility: "hidden", width: "50px" }}
                                variant="standard"
                                onChange={(e) => handleImagePreview(e, idx)}
                                multiple
                              />
                            </Stack>
                          </label>: null}
                        </div>
                      </Stack>
                    ))}
                    <RemarkButton onClick={handleAddRemark}>
                      + Add Remark
                    </RemarkButton>
                  </Stack>
                  <Stack
                    px={2}
                    direction={"row"}
                    justifyContent={"space-between"}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                    disabled = {(popOver?.remark??[]).filter(({isEditing})=>isEditing).length===0 || (popOver?.remark??[]).some(({isEditing, remark, remarkUpload})=>(isEditing && remark===""&&remarkUpload.length===0))}
                      onClick={handleSaveChanges}
                      sx={{
                        borderRadius: 2,
                        textTransform: "none",
                        height: 40,
                        fontSize: "14px",
                      }}
                    >
                      Save Changes
                    </Button>
                  </Stack>
                </>
              </Popover>
            )
          ) : (
            <Popover
              id={id}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  border: "1px solid #000000",
                  maxWidth: "587px",
                  maxHeight: "550px",
                  padding: "0 8px 16px",
                  minWidth: "500px",
                },
              }}
            >
              <>
                <Stack
                  sx={{
                    position: "sticky",
                    top: 0,
                    padding: "16px 8px 8px",
                    background: "white",
                    zIndex: 5,
                  }}
                  direction="row"
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      flexGrow: 1,
                      textAlign: "center",
                      cursor: "move",
                    }}
                  >
                    Remark of{" "}
                    {getDateDDMMYYYY(popOver?.date ? popOver?.date : popOverDate)}{" "}
                  </Typography>
                  <CloseIcon
                    sx={{ cursor: "pointer", mr: "10px" }}
                    onClick={handleClose}
                  />
                </Stack>

                <Stack gap={1} mb={2} px={2}>
                  {popOver?.remark?.map((item, idx) => (
                    <Stack
                      sx={{ border: "1px solid #7474744D", borderRadius: "8px",
                          padding: "8px" }}
                      gap={1}
                      px={2}
                    >
                      <Stack direction="row" justifyContent={"space-between"}>
                        <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                          {item?.createdBy ?? ""}{" "}
                        </Typography>
                        <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                          {getDateDDMMYYYY(popOver?.date)}{" "}
                          {getHrMin(item?.createdAt ?? "")}{" "}
                        </Typography>
                      </Stack>
                      <TextField
                        disabled={true}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                        multiline
                        fullWidth
                        value={item?.remark}
                        onChange={(e) => {
                          const updatedRemarkText = e.target.value;
                          setPopOver((prevPopOver) => ({
                            ...prevPopOver,
                            remark: prevPopOver.remark.map((rem, index) =>
                              index === idx
                                ? { ...rem, remark: updatedRemarkText , isUpdated: true}
                                : rem
                            ),
                          }));
                        }}
                        variant="standard"
                        placeholder="Type your remark..."
                        InputProps={{ disableUnderline: "true" }}
                        // onInput={handleInput}
                      />

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: "row",
                          gap: "8px",
                        }}
                      >
                        {[
                          ...(!!item?.remarkUpload?.length
                            ? item?.remarkUpload
                            : []),
                        ].map((img, index) => {
                          console.log({ img });
                          return (
                            <Box
                              // mt={3}
                              key={index}
                              style={{
                                position: "relative",
                                padding: "0px",
                              }}
                            >
                              {/* {isEditing && (
                                <CloseIcon
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: "-4px",
                                    right: "-8px",
                                    color: "white",
                                    backgroundColor: "#6C7270",
                                    borderRadius: "50%",
                                    height: "17px",
                                    width: "18px",
                                  }}
                                  onClick={() => handleDeleteImage(index, img)}
                                />
                              )} */}

                              <img
                                src={img?.url}
                                alt="Preview"
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  objectFit: "cover",
                                  margin: 1,
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleOpenImageDialog(item?.remarkUpload, index)
                                }
                              />
                            </Box>
                          );
                        })}
                        {/* {isEditing && (
                          <label>
                            <Stack
                              direction="row"
                              ml={2}
                              // mt={"4px"}
                              height={"100%"}
                              alignItems={"center"}
                            >
                              <AddPhotoAlternateIcon
                                onClick={(e) => handleImagePreview(e, idx)}
                                sx={{ color: "grey", cursor: "pointer" }}
                              />
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  color: "grey",
                                  ml: 1,
                                  cursor: "pointer",
                                }}
                              >
                                Add Image
                              </Typography>
                              <TextField
                                type="file"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                sx={{ visibility: "hidden", width: "50px" }}
                                variant="standard"
                                onChange={(e) => handleImagePreview(e, idx)}
                                multiple
                              />
                            </Stack>
                          </label>
                        )} */}
                      </div>
                    </Stack>
                  ))}
                </Stack>
              </>
            </Popover>
          )}
        </Draggable>
        <Dialog
          open={openImageDialog}
          onClose={handleCloseImageDialog}
          fullWidth
          height="400px"
          maxWidth="sm"
        >
          <DialogContent sx={{ position: "relative" }}>
            <IconButton
              sx={{ position: "absolute", top: 8, right: 8 }}
              onClick={handleCloseImageDialog}
            >
              <CloseIcon />
            </IconButton>

            <Stack direction="row" alignItems="center" justifyContent="center">
              <IconButton onClick={handlePrevImage}>
                <ArrowBackIos />
              </IconButton>

              <Box
                component="img"
                src={selectedImages[currentImageIndex]?.url}
                alt="Selected Preview"
                sx={{
                  height: "300px",
                  width: "500px",
                  objectFit: "contain",
                  borderRadius: 1,
                }}
              />

              <IconButton onClick={handleNextImage}>
                <ArrowForwardIos />
              </IconButton>
            </Stack>

            <Typography variant="caption" display="block" align="center" mt={2}>
              {currentImageIndex + 1} / {selectedImages.length}
            </Typography>
          </DialogContent>
        </Dialog>
      </>
    );
  }
);
export default RecommendationTable;
