import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../sdk";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useWarning } from "../../Provider/context";

const useRoomFilter = () => {
  const { hotelId } = useParams();
  const { authFetch } = useAuth();
  const [filterData, setFilterData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const { formEdit, setformEdit, handleRedirect } = useWarning();
  const [snackBar, setSnackBar] = useState({
    open: false,
    msg: "",
    color: "",
  });
  const getAllMappingData = useCallback(async () => {
    try {
      setIsLoading({ saveApi: false, getApi: true });
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/rate-shop-room-type-mapping`,
      });
      const { data } = await get();
      if (data) {
        setFilterData(data);
        const selected = data.reduce((prevHotel, {rateMetricHotelCode,roomDetails}) => ({
          ...prevHotel,
          [rateMetricHotelCode]: roomDetails?.filter(({ type }) => type !== "")?.reduce((prevRoom, nextRoom) => {
            switch (nextRoom?.type) {
              case "standard": {
                return ({
                  ...prevRoom,
                  standard: [
                    ...(prevRoom?.standard??[]),
                    {
                      roomDetailsId: nextRoom?.roomDetailsId,
                      hotelCode: rateMetricHotelCode,
                      roomType: nextRoom?.type
                    }
                  ]
                })
              }
              case "club": {
                return ({
                  ...prevRoom,
                  club: [
                    ...(prevRoom?.club??[]),
                    {
                      roomDetailsId: nextRoom?.roomDetailsId,
                      hotelCode: rateMetricHotelCode,
                      roomType: nextRoom?.type
                    }
                  ]
                })
              }
              case "suite": {
                return ({
                  ...prevRoom,
                  suite: [
                    ...(prevRoom?.suite??[]),
                    {
                      roomDetailsId: nextRoom?.roomDetailsId,
                      hotelCode: rateMetricHotelCode,
                      roomType: nextRoom?.type
                    }
                  ]
                })
              }
              default: {
                return prevRoom
              }
            }
          },{})
        }), {})
        setSelectedFilter(selected)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading({ saveApi: false, getApi: false });
    }
  }, [authFetch, hotelId]);


  const handleRoomChange = useCallback((roomDetailsIds, hotelCode, roomType) => {
    setformEdit(true);
    setSelectedFilter((prev) => ({
      ...prev,
      [hotelCode]: {
        ...prev?.[hotelCode] ?? {},
        [roomType]: roomDetailsIds ? roomDetailsIds.map((roomDetailsId)=>
          ({
            roomDetailsId,
            hotelCode,
            roomType
          })
        ): []
      }
    }))
  },[setSelectedFilter,setformEdit])
  
  const saveFilterData = useCallback(
    async ({ clear }) => {
      try {
        setIsLoading({ getApi: false, saveApi: true });
        setformEdit(false);
        const { post } = await authFetch({
          path: `/hotel/${hotelId}/rate-shop-room-type-mapping`,
        });
        const requestBody = clear
          ? []
          : filterData?.reduce((prevItem, nextItem) => {
            const { standard, club, suite } = { standard: [], club: [], suite: [], ...selectedFilter?.[nextItem?.rateMetricHotelCode] }
              return [...prevItem, ...nextItem?.roomDetails?.map((room) => {
                if (standard?.some(({roomDetailsId})=>(roomDetailsId === room?.roomDetailsId))) {
                  return {
                    ...standard?.find(({roomDetailsId})=>(roomDetailsId === room?.roomDetailsId)),
                    isDeleted: false
                  }
                }
                if (club?.some(({roomDetailsId})=>(roomDetailsId === room?.roomDetailsId))) {
                  return {
                    ...club?.find(({roomDetailsId})=>(roomDetailsId === room?.roomDetailsId)),
                    isDeleted: false
                  }
                }
                if (suite?.some(({roomDetailsId})=>(roomDetailsId === room?.roomDetailsId))) {
                  return {
                    ...suite?.find(({roomDetailsId})=>(roomDetailsId === room?.roomDetailsId)),
                    isDeleted: false
                  }
                }
                if (room?.type !== "") {
                  return {
                    roomDetailsId: room.roomDetailsId,
                    roomType: room.type,
                    hotelCode: nextItem?.rateMetricHotelCode,
                    isDeleted: true
                  }
                }
                return false;
              })?.filter((room)=>room)??[]];
          },[]);
        const { response, data } = await post(requestBody);

        if (response.status === 201) {
          setSnackBar({
            open: true,
            msg: "Saved Successfully",
            color: "#228B22",
          });
        } else {
          setSnackBar({
            open: true,
            msg: data?.messageToUser || "Something went wrong",
            color: "#CA3433",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        getAllMappingData();
        setIsLoading({ getApi: false, saveApi: false });
      }
    },
    [authFetch, getAllMappingData, hotelId, filterData, setformEdit, selectedFilter]
  );
  
  useEffect(() => {
    getAllMappingData();
  }, [getAllMappingData]);
  const [isLoading, setIsLoading] = useState({
    getApi: false,
    saveApi: false,
  });


  return {
    hotelId,
    formEdit,
    snackBar,
    filterData,
    handleRedirect,
    isLoading,
    selectedFilter,
    handleRoomChange,
    saveFilterData
  };
};

export default useRoomFilter;
