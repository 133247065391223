import { format } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../sdk";

export default function usePriceChecker() {
  const [selectedMonthYear, setSelectedMonthYear] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [addEmailDialog, setAddEmailDialog] = useState(false);

  const open = Boolean(anchorEl);
  const exportFileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const exportFileMenuClose = () => {
    setAnchorEl(null);
  };

  const toDay = new Date();
  const lastDay = new Date(toDay.getFullYear(), toDay.getMonth() + 1, 0);
  const defaultFromValue = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const defaultToValue = {
    year: lastDay.getFullYear(),
    month: lastDay.getMonth() + 1,
    day: lastDay.getDate(),
  };

  const [fromDateNew, setFromDateNew] = useState(defaultFromValue);
  const [toDateNew, settoDateNew] = useState(defaultToValue);
  const [pendingCredits, setPendingCredits] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [canRateMetricLoading, setcanrateMetricLoading] = useState(false);

  useEffect(() => {
    const currentDate = new Date();

    const firstdayOfMonth =
      selectedMonthYear.getMonth() === currentDate.getMonth() &&
      selectedMonthYear.getFullYear() === currentDate.getFullYear()
        ? currentDate.getDate()
        : 1;

    const lastDayOfMonth = new Date(
      selectedMonthYear.getFullYear(),
      selectedMonthYear.getMonth() + 1,
      0
    ).getDate();

    setFromDateNew({
      day: firstdayOfMonth,
      month: selectedMonthYear.getMonth() + 1,
      year: selectedMonthYear.getFullYear(),
    });
    settoDateNew({
      day: lastDayOfMonth,
      month: selectedMonthYear.getMonth() + 1,
      year: selectedMonthYear.getFullYear(),
    });
  }, [selectedMonthYear]);

  const formatInputValue = () => {
    if (fromDateNew) {
      const value =
        `${fromDateNew.day < 10 ? "0" + fromDateNew.day : fromDateNew.day}` +
        "/" +
        `${
          fromDateNew.month < 10 ? "0" + fromDateNew.month : fromDateNew.month
        }` +
        "/" +
        `${fromDateNew.year}`;
      return value;
    }
  };

  const formatInputValueToDate = () => {
    if (toDateNew) {
      const value =
        `${toDateNew.day < 10 ? "0" + toDateNew.day : toDateNew.day}` +
        "/" +
        `${toDateNew.month < 10 ? "0" + toDateNew.month : toDateNew.month}` +
        "/" +
        `${toDateNew.year}`;
      return value;
    }
  };

  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValue()}
      style={{
        textAlign: "left",
        marginBottom: "2px",
        fontSize: "14px",
        borderRadius: "10px",
        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );

  const renderCustomInputToDate = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValueToDate()}
      style={{
        textAlign: "left",
        marginBottom: "2px",
        fontSize: "14px",
        borderRadius: "10px",
        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );

  const [calendarData, setCalendarData] = useState([]);
  const [tableData, setTableData] = useState({ competitors: [], data: [] });
  const [mobileChannels, setMobileChannels] = useState([]);
  const [pastDateData, setPastDateData] = useState([]);
  const [channels, setChannels] = useState([]);
  const [allSources, setAllSources] = useState([]);

  const [oldestRate, setOldestRate] = useState(null);
  const sources = ["Desktop", "Mobile"];
  const nights = ["1 Night", "2 Nights", "3 Nights"];
  const guests = ["1 Guest", "2 Guests"];
  const roomTypes = [
    {value: "", label: "Any"},
    {value: "standard", label: "Standard"},
    {value: "club", label: "Club"},
    {value: "suite", label: "Suite"}
  ];
  const mealTypes = [
    {value: "", label: "Any"}, 
    {value: "EP", label: "Room Only"}, 
    {value: "CP", label: "Bed & Breakfast"},
    {value: "MEP", label: "2 Meals"},
    {value: "AP", label: "Whole Meal"}
  ];
  const fileExport = [
    "Next 60 Days",
    "Next 90 Days",
    "Selected Month",
    "Custom",
  ];
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [clickonRefreshBtn, setClickonRefreshBtn] = React.useState(true);
  const [filters, setFilters] = useState({
    channelIds: [2],
    sourceName: [sources?.[0].toString()],
    nightName: [nights?.[0].toString()],
    guestName: [guests?.[0].toString()],
    roomType: [roomTypes?.[0].toString()],
    mealPlan: [mealTypes?.[0].toString()],
  });
  const [exportName, setExportName] = useState("");

  const median = useCallback((arr) => {
    const filteredArray = arr.filter(Boolean);
    if (!filteredArray?.length) return 0;
    const mid = Math.floor(filteredArray?.length / 2),
      nums = [...filteredArray].sort((a, b) => a - b);
    return filteredArray?.length % 2 !== 0
      ? nums[mid]
      : (nums[mid - 1] + nums[mid]) / 2;
  }, []);

  const average = useCallback((arr) => {
    const filteredArray = arr?.filter(Boolean);
    if (!filteredArray?.length) return 0;
    const sum = filteredArray.reduce((sum, value) => sum + value, 0);
    return (sum / filteredArray?.length).toFixed(2);
  }, []);

  const { hotels, token, authFetch, permission } = useAuth();

  const { hotelId } = useParams();
  const history = useHistory();
  const isCalledOnce = useRef(false);
  const searchParams = new URLSearchParams(history.location.search);

  useEffect(() => {
    const queryDate = searchParams.get("date");
    const parsedDate = new Date(queryDate);

    const year = selectedMonthYear.getFullYear();
    const month = selectedMonthYear.getMonth();
    const date = new Date(year, month, 1);
    const finalDate = format(date, "yyyy-MM-dd");
    !queryDate && searchParams.set("date", finalDate);
    history.replace({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    });

    queryDate && setSelectedMonthYear(parsedDate);
  }, []);

  const getRateShop = useCallback(async () => {
    try {
      setIsLoading(true);
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/rate-shop`,
      });

      const startDate = new Date(
        selectedMonthYear.getFullYear(),
        selectedMonthYear.getMonth(),
        1
      );
      const endDate = new Date(
        selectedMonthYear.getFullYear(),
        selectedMonthYear.getMonth() + 1,
        0
      );
      const queryRoomType = searchParams.get("roomType")??"";
      const queryMealPlan = searchParams.get("mealPlan")??"";
      const start = formatDate(startDate);
      const end = formatDate(endDate);

      const body = {
        startDate: start,
        endDate: end,
        sources: filters.channelIds,
        los: filters?.nightName?.[0]?.[0],
        occupancy: filters?.guestName?.[0]?.[0],
        hotelOnly: false,
        mealPlan: queryMealPlan,
        roomType: queryRoomType
      };

      const { data, error, response } = await get(
        `?${new URLSearchParams(body)}`
      );

      if (response?.ok) {
        const allDates = getDatesArray(startDate, endDate);
        setOldestRate(data.oldestRate);
        setPendingCredits(data?.monthlyPendingCredits);

        data.finalResult = data?.finalResult?.map((item) => {
          item.hotelResult = item.hotelResult?.[0];
          return item;
        });

        const competitors = data?.finalResult?.find(
          (data) => data.competitorsResults
        )?.competitorsResults;
        const paddedData = allDates?.map((date, index) => {
          const matchingData =
            data &&
            data?.finalResult?.find(
              (d) => d?.rateDate?.slice(0, 10) === date?.slice(0, 10)
            );

          if (matchingData) {
            return {
              ...matchingData,
              competitorsResults: (matchingData?.competitorsResults ?? [])?.map(
                (competitorsResult) => ({
                  ...competitorsResult,
                  minPrice:
                    filters.channelIds?.length === 1
                      ? competitorsResult?.sourceSortedArray?.find(
                          ({ sourceId }) => sourceId === filters.channelIds?.[0]
                        )?.price
                      : competitorsResult.minPrice,
                })
              ),
              medianCompetitorPrice: median([
                ...(matchingData?.competitorsResults ?? [])?.map((element) => {
                  if (filters.channelIds?.length === 1)
                    return element?.sourceSortedArray?.find(
                      ({ sourceId }) => sourceId === filters.channelIds?.[0]
                    )?.price;

                  return element?.minPrice;
                }),
              ]),

              meanCompetitorPrice: average([
                ...(matchingData?.competitorsResults ?? [])?.filter(({minPrice})=>minPrice!==-2).map((element) => {
                  if (filters.channelIds?.length === 1)
                    return element?.sourceSortedArray?.find(
                      ({ sourceId }) => sourceId === filters.channelIds?.[0]
                    )?.price;

                  return element?.minPrice;
                }),
              ]),
              // baseDemandLevels: demandData?.baseDemandLevels[index] ?? null,
            };
          } else {
            return {
              rateDate: date,
              minHotelPrice: "",
              meanCompetitorPrice: "",
              hotelResult: {
                hotelId: "",
                hotelName: "",
                minPrice: "",
                sourceSortedArray: [],
              },
              competitorsResults: competitors?.map(() => ({
                hotelId: "",
                hotelName: "",
                minPrice: "",
                sourceSortedArray: [
                  {
                    sourceId: "",
                    price: "",
                  },
                  {
                    sourceId: "",
                    price: "",
                  },
                ],
              })),
              // baseDemandLevels: demandData?.baseDemandLevels[index] ?? null,
            };
          }
        });
        setTableData({
          competitors,
          data: paddedData?.map((data) => ({
            ...data,
            rateDate: formatDate(data.rateDate),
          })),
        });

        const dataSorted = paddedData.sort(function (a, b) {
          return new Date(a.rateDate) - new Date(b.rateDate);
        });
        if (format(new Date(dataSorted?.[0]?.rateDate), "e") === "2") {
          setCalendarData(dataSorted);
        } else if (format(new Date(dataSorted?.[0]?.rateDate), "e") === "1") {
          setCalendarData([...new Array(6).fill(""), ...dataSorted]);
        } else {
          setCalendarData([
            ...new Array(
              Number(format(new Date(dataSorted?.[0]?.rateDate), "e")) - 2
            ).fill(""),
            ...dataSorted,
          ]);
        }
      } else {
        setErrorSnackbar(true);
        setErrorMessage(data?.messageToUser || "Something went wrong");
        console.log(error);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [
    authFetch,
    average,
    filters.channelIds,
    filters.guestName,
    filters.nightName,
    searchParams,
    hotelId,
    median,
    selectedMonthYear,
  ]);

  const getLastRateShopDetails = useCallback(async () => {
    try {
      const firstDay = format(selectedMonthYear, "yyyy-MM-dd");

      const { get } = await authFetch({
        path: `/hotel/${hotelId}/last-search-result-for-month?date=${firstDay}&hotelOnly=false`,
      });

      const { data, response } = await get();

      isCalledOnce.current = true;

      const querySources = parseInt(searchParams.get("sources"));
      const queryLos = searchParams.get("los");
      const queryOccupancy = searchParams.get("occupancy");
      const queryRoomType = searchParams.get("roomType");
      const queryMealPlan = searchParams.get("mealPlan");

      if (data && data?.los && response.status === 200) {
        setFilters({
          ...filters,
          channelIds: querySources ? [querySources] : data?.sources,
          guestName: guests.filter((item) =>
            queryOccupancy
              ? item[0] === queryOccupancy
              : item[0] === data?.occupancy?.toString()
          ),
          roomType: roomTypes.filter((item)=>
            queryRoomType ? 
            item.value === queryRoomType
            : item.value === ""
          ),
          mealPlan: mealTypes.filter((item)=>
            queryMealPlan ? 
            item.value === queryMealPlan
            : item.value === ""
          ),
          nightName: nights.filter((item) =>
            queryLos ? item[0] === queryLos : item[0] === data?.los?.toString()
          ),
        });

        !querySources && searchParams.set("sources", data?.sources);
        !queryLos && searchParams.set("los", data?.los);
        !queryOccupancy && searchParams.set("occupancy", data?.occupancy);

        const lastRateShopSource = allSources.find(
          (source) => source?.sourceId === data?.sources[0]
        );

        const sourceName = lastRateShopSource?.isMobile
          ? sources[1]
          : sources[0];

        setFilters((prv) => ({
          ...prv,
          sourceName: [sourceName],
          channelIds: [lastRateShopSource?.sourceId],
        }));

        history.replace({
          pathname: history.location.pathname,
          search: searchParams.toString(),
        });
      } else {
        !querySources && searchParams.set("sources", filters?.channelIds);
        !queryLos && searchParams.set("los", filters?.nightName?.[0][0]);
        !queryOccupancy &&
          searchParams.set("occupancy", filters?.guestName?.[0][0]);

        history.replace({
          pathname: history.location.pathname,
          search: searchParams.toString(),
        });
        getRateShop();
      }
    } catch (err) {
      console.log(err);
    }
  }, [
    authFetch,
    filters,
    getRateShop,
    guests,
    history,
    hotelId,
    nights,
    searchParams,
    selectedMonthYear,
  ]);
  useEffect(() => {
    if (!token || !channels?.length) return;
    getLastRateShopDetails();
  }, [token, channels.length]);
  //api to get sources name

  const getSources = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/sources`,
      });
      const { data, response } = await get();
      if (response?.ok) {
        setAllSources(data);
        setChannels(data.filter((data) => !data?.isMobile));
        setMobileChannels(data.filter((data) => data?.isMobile));
      } else {
        setErrorSnackbar(true);
        setErrorMessage(data?.messageToUser || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
    }
  }, [authFetch, hotelId]);

  // const seeStatus = useCallback(async () => {
  //   try {
  //     const { get } = await authFetch({
  //       path: `/hotel/${hotelId}/last-search-status`,
  //     });
  //     const { data, error } = await get();
  //     if (data) {
  //       if (data?.isOverlapping !== lastSearchData?.isOverlapping) {
  //         getRateShop();
  //       }
  //       setLastSearchData(data);
  //     }
  //     if (data.canSearch) {
  //       setClickonRefreshBtn(true);
  //       getRateShop();
  //     } else if (!data.canSearch) {
  //       setClickonRefreshBtn(false);
  //     } else {
  //       setClickonRefreshBtn(true);
  //       console.log(error);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, [authFetch, getRateShop, hotelId, lastSearchData?.isOverlapping]);

  // api to get rateshop when any of the dropdowns is clicked
  useEffect(() => {
    if (!isCalledOnce.current) return;
    if (
      filters.channelIds?.length ||
      filters.guestName?.length ||
      filters.nightName?.length ||
      filters.sourceName?.length
    ) {
      getRateShop();
      setClickonRefreshBtn(true);
    }
  }, [filters]);

  const [showSkeleton, setShowSkeleton] = useState(true);
  useEffect(() => {
    let timeoutId;
    const fetchData = async () => {
      timeoutId = setInterval(async () => {
        setShowSkeleton(false);
        await getRateShop();
        setShowSkeleton(true);
      }, 30 * 1000);
    };
    fetchData();
    return () => clearInterval(timeoutId);
  }, [getRateShop]);

  function getDatesArray(startDate, endDate) {
    const dates = [];
    const currentDate = new Date(startDate);
    const endDateObj = new Date(endDate);
    while (currentDate <= endDateObj) {
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const dateString = `${year}-${month}-${day}`;
      dates.push(dateString);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  function formatDate(date) {
    const year = new Date(date).getFullYear();
    const month = (new Date(date).getMonth() + 1).toString().padStart(2, "0");
    const day = new Date(date).getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (!selectedMonthYear || !isCalledOnce.current) return;
    getLastRateShopDetails();
    // getRateShop();
    setClickonRefreshBtn(true);
  }, [selectedMonthYear]);

  const [hotelList, setHotelList] = useState([]);

  const [openCustom, setOpenCustom] = React.useState(false);
  const openCustomDialog = () => {
    setOpenCustom(true);
  };

  const closeCustomDialog = () => {
    setOpenCustom(false);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [togglePage, setTogglePage] = useState(false);

  //api to check can post metric is valid or not
  const canPostRateMetric = async (values) => {
    setcanrateMetricLoading(true);
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/can-rate-metric`,
    });
    const losIndex = nights.indexOf(filters.nightName?.[0]);
    const los = losIndex >= 0 ? losIndex + 1 : null;

    const guestsIndex = guests.indexOf(filters.guestName?.[0]);
    const occupancy = guestsIndex >= 0 ? guestsIndex + 1 : null;
    const horizonrange = values?.refreshDate
      ? `${format(new Date(values?.refreshDate), "yyyy/MM/dd")}-${format(
          new Date(values?.refreshDate),
          "yyyy/MM/dd"
        )}`
      : `${fromDateNew.year}/${fromDateNew.month
          ?.toString()
          ?.padStart(2, "0")}/${fromDateNew.day
          ?.toString()
          ?.padStart(2, "0")}-${
          toDateNew.year
        }/${toDateNew.month
          .toString()
          .padStart(2, "0")}/${toDateNew.day?.toString()?.padStart(2, "0")}`;
    const horizon = toDateNew.day - fromDateNew.day + 1;

    const requestBody = {
      sources: filters.channelIds,
      los: los || null,
      occupancy: occupancy || null,
      horizonrange,
      horizon,
      hotelOnly: false,
    };

    try {
      const { response, data } = await post(requestBody);
      if (response.status === 200) {
        postRateMetric({
          email: values?.email,
          refreshDate: values?.refreshDate,
        });
      } else {
        setErrorSnackbar(true);
        setErrorMessage(data?.messageToUser || "Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  //api to post rate metric
  const postRateMetric = async (values) => {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/rate-metric`,
    });

    const losIndex = nights.indexOf(filters.nightName?.[0]);
    const los = losIndex >= 0 ? losIndex + 1 : null;

    const guestsIndex = guests.indexOf(filters.guestName?.[0]);
    const occupancy = guestsIndex >= 0 ? guestsIndex + 1 : null;

    const horizonrange = values?.refreshDate
      ? `${format(new Date(values?.refreshDate), "yyyy/MM/dd")}-${format(
          new Date(values?.refreshDate),
          "yyyy/MM/dd"
        )}`
      : `${fromDateNew.year}/${fromDateNew.month
          ?.toString()
          ?.padStart(2, "0")}/${fromDateNew.day
          ?.toString()
          ?.padStart(2, "0")}-${
          toDateNew.year
        }/${toDateNew.month
          .toString()
          .padStart(2, "0")}/${toDateNew.day?.toString()?.padStart(2, "0")}`;

    const horizon = toDateNew.day - fromDateNew.day + 1;

    const requestBody = {
      sources: filters.channelIds,
      los: los || null,
      occupancy: occupancy || null,
      horizonrange,
      horizon,
      hotelOnly: false,
      email: values.email,
    };

    const { data, error } = await post(requestBody);
    if (data && data?.statusCode !== 400) {
      getRateShop();
    } else {
      console.error(error);
      setErrorMessage(data?.messageToUser);
      setErrorSnackbar(true);
    }
    setcanrateMetricLoading(false);
  };

  //   // api to post past data
  //   async function getPastData(date) {
  //     const { get } = await authFetch({
  //       path: `/hotel/${hotelId}/past-data-for-a-date`,
  //     });
  //     const losIndex = nights.indexOf(filters.nightName?.[0]);
  //     const los = losIndex >= 0 ? losIndex + 1 : null;
  //     const guestsIndex = guests.indexOf(filters.guestName?.[0]);
  //     const occupancy = guestsIndex >= 0 ? guestsIndex + 1 : null;

  //     const endDate = new Date(
  //       selectedMonthYear.getFullYear(),
  //       selectedMonthYear.getMonth() + 1,
  //       0
  //     );
  //     const end = formatDate(endDate);

  //     const body = {
  //       date: date ?? formatDate(new Date()),
  //       numberOfDays: 30,
  //       sources: filters.channelIds,
  //       los: los?.toString(),
  //       occupancy: occupancy?.toString(),
  //     };

  //     const { data, error } = await get(`?${new URLSearchParams(body)}`);
  //     if (data && data.statusCode !== 500) {
  //       setPastDateData(data);
  //     } else {
  //       setOpenSnackbar(true);
  //       console.log(error);
  //     }
  //   }

  let listOfOrg = null;
  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "listOfOrg") {
        listOfOrg = permission[key];
      }
    }
  }

  const exportFile = useCallback(
    async (startDate, endDate) => {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/rate-shop/excel`,
      });
      const losIndex = nights.indexOf(filters.nightName?.[0]);
      const los = losIndex >= 0 ? losIndex + 1 : null;
      const guestsIndex = guests.indexOf(filters.guestName?.[0]);
      const occupancy = guestsIndex >= 0 ? guestsIndex + 1 : null;
      const body = {
        sources: filters.channelIds,
        los: los?.toString(),
        occupancy: occupancy?.toString(),
        hotelOnly: false,
        endDate: formatDate(endDate),
        startDate: formatDate(startDate),
      };

      const response = await get(`?${new URLSearchParams(body)}`);
      const blob = await response.response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${formatDate(startDate)}-${formatDate(endDate)}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    },
    [filters, hotelId, guests, nights, authFetch]
  );

  useEffect(() => {
    if (token) {
      getSources();
    } else {
      return;
    }
  }, [token]);

  useEffect(() => {
    if (!token) {
      return;
    }
    if (listOfOrg && isCalledOnce.current) {
      setHotelList(hotels);
    }
  }, [token, listOfOrg]);

  const handleCloseAddEmailDialog = () => {
    setAddEmailDialog(false);
  };

  const handleIncrementDecrementDate = useCallback(
    (tag) => {
      const year = selectedMonthYear.getFullYear();
      const month = selectedMonthYear.getMonth();
      let date = new Date(year, month, 1);

      if (tag === "left") {
        date.setMonth(date.getMonth() - 1);
      } else if (tag === "right") {
        date.setMonth(date.getMonth() + 1);
      }

      const parsedDate = format(date, "yyyy-MM-dd");
      const newSearchParams = new URLSearchParams();
      newSearchParams.set("date", parsedDate);

      history.replace({
        pathname: history.location.pathname,
        search: newSearchParams.toString(),
      });

      setSelectedMonthYear(date);
    },
    [history, selectedMonthYear]
  );

  return {
    selectedMonthYear,
    setSelectedMonthYear,
    anchorEl,
    setAnchorEl,
    addEmailDialog,
    setAddEmailDialog,
    open,
    exportFileMenuOpen,
    exportFileMenuClose,
    toDay,
    lastDay,
    fromDateNew,
    setFromDateNew,
    toDateNew,
    settoDateNew,
    pendingCredits,
    isLoading,
    formatInputValue,
    formatInputValueToDate,
    renderCustomInput,
    renderCustomInputToDate,
    calendarData,
    tableData,
    pastDateData,
    channels,
    oldestRate,
    sources,
    nights,
    guests,
    roomTypes,
    mealTypes,
    fileExport,
    openSnackbar,
    setOpenSnackbar,
    errorSnackbar,
    setErrorSnackbar,
    errorMessage,
    setErrorMessage,
    clickonRefreshBtn,
    setClickonRefreshBtn,
    filters,
    setFilters,
    exportName,
    setExportName,
    median,
    average,
    getSources,
    getRateShop,
    getDatesArray,
    formatDate,
    hotelList,
    openCustom,
    openCustomDialog,
    closeCustomDialog,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    togglePage,
    setTogglePage,
    canPostRateMetric,
    postRateMetric,
    listOfOrg,
    exportFile,
    handleCloseAddEmailDialog,
    handleIncrementDecrementDate,
    canRateMetricLoading,
    showSkeleton,
    mobileChannels,
  };
}
